import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { sendBookingRequest } from "../api/bookingManagerApi";
import { getConfig, getVirtualStops } from "../api/dbServicesApi";
import CustomAutocomplete from "../components/CustomAutocomplete";
import CustomMarker from "../components/CustomMarker";
import CustomNumberInput from "../components/CustomNumberInput";
import DetailsModal from "../components/DetailsModal";
import VirtualStopMarker from "../components/VirtualStopMarker";
import { appConfig } from "../config/appConfig";
import { CityEnum } from "../consts/CityEnum";
import { VirtualStopMarkerProps } from "../types/customProps";
import {
  AutocompleteOption,
  DetailsTableData,
  MarkerPosition,
  RequestType,
  bookingRequestData,
  maxNumbersPassengerInfo,
} from "../types/customTypes";
import { buildISOStringGMT } from "../utils/dateUtils";
import { mapRequestData } from "../utils/mappingUtils";
import { getSuspendedWarning } from "../utils/stopUtils";

dayjs.extend(utc);

// Seite für die Buchung von Fahrten, beinhaltet eine Form zur Eingabe der Infos und zusätzlich eine Leaflet-Map zur Auswahl von Start- und Zielpunkt
function MapPage() {
  // Wurde eine Buchungsanfrage gesendet, auf deren Antwort noch gewartet wird?
  const [loading, setLoading] = useState(false);
  // Ist das DetailsModal geöffnet?
  const [dialogOpen, setDialogOpen] = useState(false);

  // Einstellungen für die Karte
  const [showVirtualStops, setShowVirtualStops] = useState(false);
  const [clusterVirtualStops, setClusterVirtualStops] = useState(true);

  const [virtualStopsArray, setVirtualStopsArray] = useState<
    VirtualStopMarkerProps[]
  >([]);

  // Koordinaten der beiden Leaflet-Marker
  const [startPosition, setStartPosition] = useState<MarkerPosition | null>(
    null,
  );
  const [destPosition, setDestPosition] = useState<MarkerPosition | null>(null);

  // States zur Verwaltung der Eingabe
  const [startLocationInput, setStartLocationInput] = useState<string>("");
  const [destLocationInput, setDestLocationInput] = useState<string>("");
  const [startLocation, setStartLocation] = useState<AutocompleteOption | null>(
    null,
  );
  const [destLocation, setDestLocation] = useState<AutocompleteOption | null>(
    null,
  );
  const [requestedTime, setRequestedTime] = useState<Dayjs | null>(dayjs());
  const [requestedDay, setRequestedDay] = useState<Dayjs | null>(dayjs());
  const [name, setName] = useState<string>("");
  const [passenger, setPassenger] = useState<number>(1);
  const [phoneNr, setPhoneNr] = useState<string>("");
  const [babySeat, setBabySeat] = useState<number>(0);
  const [childSeat, setChildSeat] = useState<number>(0);
  const [stroller, setStroller] = useState<number>(0);
  const [wheelchair, setWheelchair] = useState<number>(0);
  const [useArrivalTime, setUseArrivalTime] = useState<boolean>(false);

  const [startSuspendedWarning, setStartSuspendedWarning] =
    useState<string>("");
  const [destSuspendedWarning, setDestSuspendedWarning] = useState<string>("");

  const [offersData, setOffersData] = useState<DetailsTableData | null>(null);

  const [offerExpireTime, setOfferExpireTime] = useState<number>(0);
  const [maxNumberPassengerInfo, setMaxNumbersPassengerInfo] =
    useState<maxNumbersPassengerInfo>({
      maxNumberPassengers: 1,
      maxNumberBabyseats: 1,
      maxNumberChildseats: 1,
      maxNumberStrollers: 1,
      maxNumberWheelchairs: 1,
    }); // Initialisierung mit Minimalwerten

  const bookingPaperRef = useRef<HTMLDivElement>(null);
  const [bookingPaperHeight, setBookingPaperHeight] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (bookingPaperRef.current) {
      setBookingPaperHeight(bookingPaperRef.current.offsetHeight);
    }
  }, []);

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isInputVerified() && !loading) {
      setLoading(true);

      const bookingData: bookingRequestData = {
        requestDate: {
          requestTime: buildISOStringGMT(requestedDay!, requestedTime!, false),
          requestType: useArrivalTime
            ? RequestType.ARRIVAL
            : RequestType.DEPARTURE,
        },
        origin: {
          name: startLocation!.label,
          lat: startLocation!.lat,
          lon: startLocation!.lon,
        },
        destination: {
          name: destLocation!.label,
          lat: destLocation!.lat,
          lon: destLocation!.lon,
        },
        requestOptions: {
          passenger: passenger,
          stroller: stroller,
          wheelchair: wheelchair,
          babySeat: babySeat,
          childSeat: childSeat,
        },
        userMetaData: {
          userPlatform: "CALLCENTER",
          displayName: name,
          phoneNumber: phoneNr,
        },
      };

      try {
        const response = await sendBookingRequest(bookingData);
        setOffersData(mapRequestData(response));
        setLoading(false);
        setDialogOpen(true);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const handleResetInputs = () => {
    setStartLocationInput("");
    setDestLocationInput("");
    setStartLocation(null);
    setDestLocation(null);
    setRequestedTime(null);
    setRequestedDay(null);
    setName("");
    setPassenger(0);
    setPhoneNr("");
    setBabySeat(0);
    setChildSeat(0);
    setStroller(0);
    setWheelchair(0);

    setStartPosition(null);
    setDestPosition(null);
  };
  const handleToggleShowVirtualStops = async () => {
    setShowVirtualStops((prevState) => !prevState);
    if (!showVirtualStops) {
      try {
        const response = await getVirtualStops();
        setVirtualStopsArray(
          response.map((entry: any) => ({
            id: entry.stopId,
            label: entry.description,
            nr: entry.name,
            position: {
              lat: entry.location.latitude,
              lng: entry.location.longitude,
            },
            suspendedFrom: entry.suspendedFrom,
            suspendedTo: entry.suspendedTo,
          })),
        );
      } catch (err) {
        console.log(err);
      }
    } else {
      setVirtualStopsArray([]);
    }
  };

  // Vertauscht Abhol- und Zielort
  const switchStartortZielort = () => {
    const tempOrt = startLocation;
    const tempCoords = startPosition;
    setStartLocation(destLocation);
    setDestLocation(tempOrt);
    setStartPosition(destPosition);
    setDestPosition(tempCoords);
  };

  // Prüft, ob alle notwendigen Daten ordnungsgemäß eingegeben wurden
  const isInputVerified = () => {
    if (
      !(
        startLocation &&
        destLocation &&
        requestedTime &&
        requestedDay &&
        name &&
        passenger
      )
    ) {
      return false;
    }
    if (!requestedTime.isValid() || !requestedDay.isValid()) {
      return false;
    }
    return true;
  };

  const fetchConfig = async () => {
    try {
      const response = await getConfig();
      setOfferExpireTime(response.offerExpireTime);
      setMaxNumbersPassengerInfo(response.passengerInfo);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  // Zeige Hinweis unter Autocomplete-Feldern an, falls Haltestelle temporär gesperrt
  useEffect(() => {
    if (startLocation) {
      setStartSuspendedWarning(
        getSuspendedWarning(
          startLocation?.suspendedFrom,
          startLocation?.suspendedTo,
        ),
      );
    } else {
      setStartSuspendedWarning("");
    }
  }, [startLocation]);
  useEffect(() => {
    if (destLocation) {
      setDestSuspendedWarning(
        getSuspendedWarning(
          destLocation?.suspendedFrom,
          destLocation?.suspendedTo,
        ),
      );
    } else {
      setDestSuspendedWarning("");
    }
  }, [destLocation]);

  // Funktion zum Rendern der virtuellen Haltepunkte, verhindert redundanten Code bei Clustering
  const renderMarkers = () => {
    if (!showVirtualStops || !virtualStopsArray) return null;

    return virtualStopsArray.map((entry, index) => (
      <VirtualStopMarker
        key={entry.id}
        id={entry.id}
        position={entry.position}
        nr={entry.nr}
        label={entry.label}
        suspendedFrom={entry.suspendedFrom}
        suspendedTo={entry.suspendedTo}
        setSelectedMarkerPosition={
          !startLocation
            ? setStartPosition
            : !destLocation
              ? setDestPosition
              : null
        }
        setSelectedLocation={
          !startLocation
            ? setStartLocation
            : !destLocation
              ? setDestLocation
              : null
        }
      />
    ));
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "scroll",
        }}
      >
        <Toolbar />

        <Container maxWidth={false} sx={{ mt: 3, mb: 1 }}>
          <DetailsModal
            open={dialogOpen}
            setDialogOpen={setDialogOpen}
            originAdress={startLocation?.label || "Unbekannt"}
            destinationAdress={destLocation?.label || "Unbekannt"}
            tableData={offersData}
            offerExpireTime={offerExpireTime}
          ></DetailsModal>
          <Grid container spacing={2} sx={{ flexWrap: "wrap" }}>
            <Grid item xs={true} sx={{ minWidth: 550 }}>
              <Paper
                ref={bookingPaperRef}
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "scroll",
                }}
              >
                <Grid
                  container
                  component={"form"}
                  noValidate
                  onSubmit={handleSubmitForm}
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <Typography
                      fontSize="28px"
                      fontWeight="bold"
                      align={"center"}
                    >
                      Fahrt buchen
                    </Typography>
                  </Grid>
                  <Grid item container direction="column" xs={11} spacing={2}>
                    <Grid item>
                      <CustomAutocomplete
                        label="Startort"
                        input={startLocationInput}
                        setInput={setStartLocationInput}
                        value={startLocation}
                        setValue={setStartLocation}
                        setMarkerPosition={setStartPosition}
                      />
                      {startSuspendedWarning && (
                        <Typography
                          variant="body2"
                          color={(theme) => theme.palette.warning.main}
                        >
                          {startSuspendedWarning}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <CustomAutocomplete
                        label="Zielort"
                        input={destLocationInput}
                        setInput={setDestLocationInput}
                        value={destLocation}
                        setValue={setDestLocation}
                        setMarkerPosition={setDestPosition}
                      />
                      {destSuspendedWarning && (
                        <Typography
                          variant="body2"
                          color={(theme) => theme.palette.warning.main}
                        >
                          {destSuspendedWarning}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <IconButton
                      size="large"
                      onClick={switchStartortZielort}
                      disabled={!(startLocation || destLocation)}
                    >
                      <ImportExportIcon />
                    </IconButton>
                  </Grid>
                  <Grid container item xs={12} spacing={2} alignItems="center">
                    <Grid
                      container
                      item
                      xs={6}
                      direction="row"
                      alignItems={"center"}
                    >
                      <Grid item marginX={2}>
                        <Typography
                          variant="body1"
                          fontWeight={useArrivalTime ? "normal" : "bold"}
                          color={(theme) =>
                            useArrivalTime
                              ? "inherit"
                              : theme.palette.primary.main
                          }
                          sx={{ opacity: useArrivalTime ? 0.5 : 1 }}
                        >
                          Abfahrt
                        </Typography>

                        <Switch
                          checked={useArrivalTime}
                          onChange={() =>
                            setUseArrivalTime((prevState) => !prevState)
                          }
                          color="default"
                          //Ermöglicht das Triggern des Switches mit Enter wenn im Fokus anstatt dass die Buchungsanfrage gesendet wird
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              setUseArrivalTime((prevState) => !prevState);
                            }
                          }}
                        />

                        <Typography
                          variant="body1"
                          fontWeight={useArrivalTime ? "bold" : "normal"}
                          color={(theme) =>
                            useArrivalTime
                              ? theme.palette.primary.main
                              : "inherit"
                          }
                          sx={{ opacity: useArrivalTime ? 1 : 0.5 }}
                        >
                          Ankunft
                        </Typography>
                      </Grid>
                      <Grid item xs={true}>
                        <TimePicker
                          label={useArrivalTime ? "Ankunftszeit" : "Startzeit"}
                          sx={{ width: "100%" }}
                          value={requestedTime}
                          onChange={(newValue) => setRequestedTime(newValue)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} minWidth={180}>
                      <DatePicker
                        label="Tag"
                        sx={{ width: "100%" }}
                        disablePast
                        maxDate={dayjs().add(7, "day")} // Flexa kann max. 7 Tage im Voraus gebucht werden
                        value={requestedDay}
                        onChange={(newValue) => setRequestedDay(newValue)}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomNumberInput
                      label={"Personen"}
                      value={passenger}
                      setValue={setPassenger}
                      maxValue={maxNumberPassengerInfo?.maxNumberPassengers}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Telefonnummer"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={phoneNr}
                      onChange={(e) => {
                        const input = e.target.value;
                        // Entferne alle Zeichen außer Zahlen und "+"
                        const cleanedInput = input.replace(/[^\d+\s]/g, "");
                        // Setze den Wert des Textfelds auf die gereinigte Eingabe
                        setPhoneNr(cleanedInput);
                      }}
                    />
                  </Grid>
                  {/*                   <Grid item xs={12}>
                    <br />
                  </Grid> */}
                  <Grid item xs={6}>
                    <CustomNumberInput
                      label={"Babyschale"}
                      value={babySeat}
                      setValue={setBabySeat}
                      maxValue={maxNumberPassengerInfo?.maxNumberBabyseats}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomNumberInput
                      label={"Sitzerhöhung"}
                      value={childSeat}
                      setValue={setChildSeat}
                      maxValue={maxNumberPassengerInfo?.maxNumberChildseats}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomNumberInput
                      label={"Kinderw./Rollator"}
                      value={stroller}
                      setValue={setStroller}
                      maxValue={maxNumberPassengerInfo?.maxNumberStrollers}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomNumberInput
                      label={"Rollstuhl"}
                      value={wheelchair}
                      setValue={setWheelchair}
                      maxValue={maxNumberPassengerInfo?.maxNumberWheelchairs}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <br />
                  </Grid>
                  <Grid
                    container
                    marginLeft={0}
                    direction={"row"}
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Box>
                        <Tooltip
                          arrow
                          title={
                            !isInputVerified() ? (
                              <span>
                                Folgende Informationen müssen noch angegeben
                                werden:
                                <br />
                                {!startLocation && <br />}{" "}
                                {startLocation ? null : "- Startort"}
                                {!destLocation && <br />}{" "}
                                {destLocation ? null : "- Zielort"}
                                {!requestedTime && <br />}{" "}
                                {requestedTime ? null : "- Zeit"}
                                {!requestedDay && <br />}{" "}
                                {requestedDay ? null : "- Tag"}
                                {!name && <br />} {name ? null : "- Name"}
                                {!passenger && <br />}{" "}
                                {passenger ? null : "- Personenanzahl"}
                              </span>
                            ) : null
                          }
                        >
                          <div>
                            <Button
                              variant="contained"
                              type="submit"
                              disabled={loading || !isInputVerified()}
                              sx={{
                                height: "56px",
                                minWidth: "234px",
                                position: "relative",
                              }}
                            >
                              Buchungsanfrage senden
                              {loading && (
                                <CircularProgress
                                  size={28}
                                  thickness={7}
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-14px",
                                    marginLeft: "-16px",
                                  }}
                                />
                              )}
                            </Button>
                          </div>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={handleResetInputs}
                        sx={{ height: "56px", minWidth: "234px" }}
                      >
                        Eingabe löschen
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={true} minWidth={500}>
              <Paper
                sx={{
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: bookingPaperHeight,
                  maxHeight: "calc(100vh - 100px)",
                }}
              >
                <MapContainer
                  center={
                    appConfig.city === CityEnum.Halle
                      ? [51.483148, 11.971085]
                      : [51.343479, 12.387772]
                  }
                  zoom={12}
                  scrollWheelZoom={true}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                  {clusterVirtualStops ? (
                    <MarkerClusterGroup
                      disableClusteringAtZoom={16}
                      maxClusterRadius={100}
                    >
                      {renderMarkers()}
                    </MarkerClusterGroup>
                  ) : (
                    renderMarkers()
                  )}
                  <CustomMarker
                    markerType="Start"
                    stopInfo={startLocation}
                    position={startPosition}
                    setPosition={setStartPosition}
                    setStopInfo={setStartLocation}
                    exSecondMarker={!!destPosition}
                  ></CustomMarker>
                  <CustomMarker
                    markerType="Ziel"
                    stopInfo={destLocation}
                    position={destPosition}
                    setPosition={setDestPosition}
                    setStopInfo={setDestLocation}
                    exSecondMarker={!!startPosition}
                  ></CustomMarker>
                </MapContainer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showVirtualStops}
                        onChange={handleToggleShowVirtualStops}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Virtuelle Haltepunkte"
                    sx={{ marginBottom: -1, alignSelf: "flex-end" }} // Zentriert den Switch horizontal
                  />
                  {showVirtualStops && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={clusterVirtualStops}
                          onChange={() => {
                            setClusterVirtualStops((prevState) => !prevState);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Haltepunkte clustern"
                      sx={{ marginBottom: -1, alignSelf: "flex-end" }} // Zentriert den Switch horizontal
                    />
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default MapPage;
